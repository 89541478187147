<template>
  <div v-loading="loaders.resource" class="p-4">
    <h1 class="py-3">Our Blog</h1>
    <div>
      <url-card
        ref="urlCardForm"
        :url="blog.url"
        :status="blog.status"
        :url-required="true">
      </url-card>
    </div>
    <el-button type="primary" class="px-5 btn-primary-action font-weight-bold" @click="updateBlogData">Update</el-button>
  </div>
</template>

<script>
import UrlCard from "@/views/Components/ContentSection/UrlCard"

export default {
  name: "Blog",
  components: {
    UrlCard
  },
  data() {
    return {
      loaders: {
        resource: false
      },
      blog: {
        url: '',
        status: '1'
      },
    }
  },
  created() {
    this.fetchBlogData()
  },
  methods: {

    /**
     * Fetch the Blog Data
     */
    async fetchBlogData() {
      let vm = this
      vm.loaders.resource = true
      try {
        const response = await vm.$store.dispatch('_fetchBlogWebsite')
        if (response.data.data) vm.blog = response.data.data
      }
      catch (error) {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Our Blog',
          message: message
        })
      }
      vm.loaders.resource = false
    },

    /**
     * Update Blog data.
     */
    async updateBlogData() {
      if (this.$refs.urlCardForm.formValidate()) {
        let vm = this
        vm.loaders.resource = true
        const formData = this.$refs.urlCardForm.form
        try {
          await vm.$store.dispatch('_updateBlogWebsite', formData)
          vm.$notify.success({
            title: 'Our Blog',
            message: 'Blog Updated Successfully. Always check to see how your changes look in the Baritastic app.'
          })
          await vm.fetchBlogData()
        }
        catch (error) {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Our Blog',
            message: message
          })
        }
        vm.loaders.resource = false
      }
    },
  }
}
</script>

<style scoped>

</style>
